<template>
  <v-autocomplete
    :items="colleges"
    :loading="loading"
    v-model="selected"
    :label="label || 'College'"
    color="color3"
    item-color="color3"
    item-value="name"
    :item-text="item => item.name === item.abbr || !item.abbr ? item.name : `${item.name} - ${item.abbr}`"
    :disabled="disable"
    :hide-details="!!disable"
    @blur="$emit('blur')"
    :error-messages="errors"
    ref="input"
  >
    <template v-slot:selection="data">
      <v-list-item-avatar>
        <img :src="data.item.logoUrl">
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.abbr"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:item="data">
      <v-list-item-avatar>
        <img :src="data.item.logoUrl">
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.abbr"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: ['value', 'label', 'disable', 'errors'],
  data () {
    return {
      loading: false,
      colleges: [],
      selected: this.value
    }
  },
  methods: {
    getData (force) {
      if (this.colleges.length && !force) return
      this.loading = true
      this.$VBL.college.list()
        .then(r => {
          this.colleges = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    handleInput (e) {
      this.$emit('input', this.selected)
      this.$refs.input.blur()
    }
  },
  watch: {
    selected: 'handleInput'
  },
  mounted () {
    this.getData()
  }
}
</script>
